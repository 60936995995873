.preloader {
	background: #fff;
	position: fixed;
	left: 0;
	top: 0;
	width: 100%;
	height: 100%;
	z-index: 9999
}

.preloader>div {
	position: absolute;
	top: 50%;
	left: 50%;
	margin-top: -60px;
	margin-left: -98px;
	width: 195px
}

.preloader>div>figure {
	width: 100px;
	margin: 0 auto
}

.preloader>div>figure>img {
	text-align: center;
	display: block;
	width: 100%
}


.spinner {
	display: block;
	position: relative;
	width: 64px;
	height: 44px;
	margin: 0 auto;
	text-align: center
}

.spinner div {
	position: absolute;
	top: 20px;
	width: 11px;
	height: 11px;
	border-radius: 50%;
	background: #2c2b2a;
	animation-timing-function: cubic-bezier(0, 1, 1, 0)
}

.spinner div:nth-child(1) {
	left: 6px;
	animation: lds-ellipsis1 .6s infinite
}

.spinner div:nth-child(2) {
	left: 6px;
	animation: lds-ellipsis2 .6s infinite
}

.spinner div:nth-child(3) {
	left: 26px;
	animation: lds-ellipsis2 .6s infinite
}

.spinner div:nth-child(4) {
	left: 45px;
	animation: lds-ellipsis3 .6s infinite
}

@keyframes lds-ellipsis1 { 0%{
	transform: scale(0)
}

100%{
transform
:scale(1)
}
}
@keyframes lds-ellipsis3 { 0%{
	transform: scale(1)
}

100%{
transform
:scale(0)
}
}
@keyframes lds-ellipsis2 { 0%{
	transform: translate(0, 0)
}

100%{
transform
:translate
(19px
,
0)
}
}