
ul {
	margin: 0;
	padding: 0
}

li {
	list-style-type: none
}
a {
	text-decoration: none
}
.bg-color-first{
	background: #067FD0 0% 0% no-repeat padding-box;
	opacity: 1;
  }

a .nav-mod-link{
	text-decoration: none;

	/* font-size: 14px; */
	color: #2c2100;
	/* padding: 7px; */
	/* line-height: 16px; */
	/* width: 100%; */
	/* display: inline-block; */
	/* background-color: #f5aa33; */
	/* white-space: break-spaces; */
	/* border-bottom: 1px solid #d18916; */
	/* box-sizing: border-box */
}
.nav-fix-bgc{
	background-color:#f5aa33
}

.nav-btn-bgimg {
	background-image:var(--bs-navbar-toggler-icon-bg) !important
}
.top-left-auto {
	top: auto ; 
	left: auto ;
}
.has-submenu>a {
	text-decoration: none
}
.dropdown>a {
	text-decoration: none
}
.dropdown>a {
    background-image: url('../images/down-chevron.svg');
    background-position: 176px 10px;
    background-repeat: no-repeat;
}
/* .has-submenu>a {
	background-image: url(../images/down-chevron.svg);
	background-position: 97% center;
	background-repeat: no-repeat;
} */
.form-switch .form-check-input {
    --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e");
    width: 2em;
    margin-left: -2.5em;
    background-image: var(--bs-form-switch-bg);
    background-position: left center;
    border-radius: 2em;
    transition: background-position .15s ease-in-out;
}
.form-switch .form-check-input:focus {
    /* --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e"); */
    /* --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%2386b7fe'/%3e%3c/svg%3e"); */
	--bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='rgba%280, 0, 0, 0.25%29'/%3e%3c/svg%3e")
}
.form-switch .form-check-input:checked {
    background-position: right center;
    --bs-form-switch-bg: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='%23fff'/%3e%3c/svg%3e");
}
.form-check-input:checked {
    background-color: #c1c1c1;
    border-color: #c1c1c1;
}
.form-check-input:focus {    
	border-color: #bbc2cd;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13,110,253,.25);
}

@media screen and (max-width: 767px){
	.has-submenu>a {
		background-image: url(../images/down-chevron.svg);
		background-position: 97% center;
		background-repeat: no-repeat;
	}
}

.sitwraper {
  width: 100% !important;
  display: block !important
}

.sitwraper {
	width: 100%;
	display: block;
	background-color: #2c70c3
}
.topheader {
	display: flex;
	justify-content: flex-end;
	background-color: #2c70c3;
	/* padding: 2px; */
	padding: 0px;
	box-sizing: border-box;
	align-items: center;
	padding-left: 15px;
	padding-right: 15px;
	/* min-height: 40px */
}
.topheader a {
	color: #fff
}
.topheader {
  width: 100% !important
}

.thright>ul {
	margin-bottom: 0
}

/* .thright>ul>li+li {
	margin-left: 16px
} */

.thright>ul>li>a:hover {
	text-decoration: underline;
	color: #fff
}

.thright .dropdown-menu {
	padding: 4px 0
}


.thright .dropdown-menu a:hover {
	background-color: #e5e5e5
}
.topheader .thright .dropdown-menu a {
	color: #111;
	padding: 6px 10px
}

.topheader .thright .lang_select.show button {
	color: #fff;
	text-decoration: underline
}

.topheader .thright .lang_select button {
	background: transparent;
	border: 0;
	font-size: 13px;
	color: #fff
}

.topheader .thright .lang_select button:hover, .topheader .thright .lang_select button:focus
	{
	border: none !important;
	outline: none
}

.thright.rhmenu ul {
	display: flex
}

.rhmenu ul li a {
	font-size: 12px
}

.thright.rhmenu ul li a {
	padding: 3px
}

.rhmenu {
	width: auto;
	display: flex;
	justify-content: flex-end;
	align-items: center
}

.thright.rhmenu ul {
	display: flex
}


.topheader .thright .lang_select.show button {
	color: #fff;
	text-decoration: underline
}

.topheader .thright .lang_select button {
	background: transparent;
	border: 0;
	font-size: 13px;
	color: #fff
}

.topheader .thright .lang_select button:hover, .topheader .thright .lang_select button:focus
	{
	border: none !important;
	outline: none
}

.thright .dropdown-menu {
	padding: 4px 0
}

.thright .dropdown-menu a:hover {
	background-color: #e5e5e5
}

.topheader .thright .dropdown-menu a {
	color: #111;
	padding: 6px 10px
}

.font_view>a {
	background: #303030;
	padding: 4px 6px;
	margin-right: 4px;
	min-width: 24px;
	display: inline-block;
	box-sizing: border-box;
	text-align: center
}

.font_view>a:last-child {
	margin-right: 0
}


.menutabbox {
	display: none
}

/* -------------------------Main Header---------------------- */

.main_header {
	background: url(../images/bg.png) center;
	background-size: cover;
	height: 100px;
	overflow: hidden
}

.main_header .header_wrap {
	display: flex;
	align-items: center;
	justify-content: center;
	padding: 12px 5rem
}

.main_header .header_wrap>figure {
	margin: 0;
	display: block
}

.main_header .header_wrap>figure>img {
	height: 100%;
	display: block;
	width: auto;
	max-height: 76px
}

.main_header .header_wrap>.mpsc_txt {
	padding: 0 16px
}

.main_header .header_wrap>.mpsc_txt>img {
	max-height: 66px;
	margin-top: -7px
}

.menutabbox {
	display: none
}
.navbar-toggle.open span:first-child {
	top: 34px;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg);
	background-color: #9ba7ca
}

.navbar-toggle.open span:nth-child(2) {
	visibility: hidden
}

.navbar-toggle.open span:last-child {
	width: 100%;
	top: 34px;
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg)
}
@media screen and (max-width:767px) {
	.menutabbox {
		display: block;
		position: absolute;
		right: 0;
		background: #ffff;
		padding: 0;
		min-height: 40px
	}
	.main_header .header_wrap>figure>img {
		width: 8vw
	}
	.main_header .header_wrap>figure.logo>img {
		width: 9vw
	}
	.main_header .header_wrap>.mpsc_txt>img {
		width: 60vw
	}
	#mobileToggle {
		display: block;
		width: 28px;
		height: 30px;
		margin: 0 10px;
		padding-top: 19px
	}
	#mobileToggle span:after, #mobileToggle span:before {
		content: "";
		position: absolute;
		left: 0;
		top: -9px
	}
	#mobileToggle span:after {
		top: 9px
	}
	#mobileToggle span {
		position: relative;
		display: block
	}
	#mobileToggle span, #mobileToggle span:after, #mobileToggle span:before
		{
		width: 100%;
		height: 3px;
		background-color: #2c70c3;
		transition: all .3s;
		backface-visibility: hidden
	}
	#mobileToggle.on span {
		background-color: transparent
	}
	#mobileToggle.on span:before {
		transform: rotate(45deg) translate(5px, 5px)
	}
	#mobileToggle.on span:after {
		transform: rotate(-45deg) translate(7px, -8px)
	}
}

@media screen and (max-width:599px) {
	.main_header {
		height: auto
	}
}


/* ---------------- */
ul.mainmenu li>ul>li>a {
	font-size: 14px;
	color: #2c2100;
	padding: 7px;
	line-height: 16px;
	width: 100%;
	display: inline-block;
	background-color: #f5aa33;
	white-space: break-spaces;
	border-bottom: 1px solid #d18916;
	box-sizing: border-box
}

.fullmenuwrapper .maincontainer {
	width: 1030px
}

.fullmenuwrapper {
	width: 100%;
	display: block;
	background-color: #f5aa33
}

.menuwraper>ul>li:hover ul.submenu {
	display: block !important
}


.menuwraper>ul {
	display: flex;
	height: 100%;
	padding-top: 2px;
	box-sizing: border-box;
	padding-bottom: 2px;
	margin: 0;
	justify-content: center
}

.menuwraper>ul li:last-child {
	border-right: 0
}

.menuwraper>ul>li>a {
	font-size: 14px;
	color: #2c2100;
	padding: 0;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	font-weight: 700;
	text-transform: uppercase;
	padding-left: 10px;
	padding-right: 10px;
	border-right: 1px solid #da921f;
	box-sizing: border-box;
	transition: .3s
}

.menuwraper>ul>li>a:hover {
	background-color: #e09929;
	text-decoration: none;
}
.menuwraper>ul>li>ul>li>a:hover {
	background-color: #e09929;
	text-decoration: none;
}
.has-submenu>ul>li>a:hover {
	background-color: #e09929;
	text-decoration: none;
}
.has-submenu>ul>li>ul>li>a:hover {
	background-color: #e09929;
	text-decoration: none;
}

.menuwraper {
	/* height: 44px; */
	display: block
}
.menuwraper>ul>li {
	height: 100%;
	align-items: center;
	display: flex;
	box-sizing: border-box;
	position: relative
}
.mainmenubox>li .submenuwrap>li .submenuwrap {
	left: 100%;
	top: 0;
	margin-top: 10px
}
.mainmenubox>li {
	display: inline-block;
	position: relative
}
li.homeicon a img {
	width: 20px
}
.search_wrap {
	padding: 0 10px;
	display: block;
	position: relative
}

.search_wrap>input {
	border: 1px solid #656565;
	padding: 2px 8px;
	box-sizing: border-box;
	font-size: 14px
}

.search_wrap>button {
	color: #111;
	position: absolute;
	right: 20px;
	top: 50%;
	margin-top: -10px;
	border: 0;
	background: #fff;
	padding: 0 3px;
	font-size: 13px
}

.search_wrap>input:focus {
	outline: none
}
.contruction_title_wrap {
	background-color: maroon;
	/* padding: 2.5px; */
	padding: 1.5px;
	color: #fff
}
/* li.check.has-submenu:hover>ul.submenuwrap {
	display: block;
	opacity: 1;
	visibility: visible;
	margin-top: 0;
	direction: rtl
} */
/* li.has-submenu:hover>ul.submenuwrap {
	display: block;
	opacity: 1;
	visibility: visible;
	margin-top: 0;
	direction: rtl
} */
.has-submenu {
	position: relative
}

.mainmenubox>li .submenuwrap>li .submenuwrap {
	left: 100%;
	top: 0;
	margin-top: 10px
}

@media only screen and (max-width: 499px) and (min-width: 400px){
	.submenuwrap.open li a {
		background-color: #ca861b;
	}
}


@media only screen and (min-width: 700px) {
	/* li.check.has-submenu:hover>ul.submenuwrap {
		display: block;
		opacity: 1;
		visibility: visible;
		margin-top: 0;
		direction: rtl
	} */
	li.check.has-submenu:hover>ul.submenuwrap {
		display: block;
		opacity: 1;
		visibility: visible;
		margin-top: 0;
		direction: rtl
	}
	li.subcheck.has-submenu:hover>ul.submenuwrap {
		display: block;
		opacity: 1;
		visibility: visible;
		margin-top: 0;
		direction: rtl;
	}
}

.submenuwrap {
	position: absolute;
	top: 100%;
	left: 0;
	z-index: 1000;
	padding: 10px 0;
	list-style: none;
	min-width: 200px;
	text-align: left;
	visibility: hidden;
	opacity: 0;
	margin-top: 20px;
	-webkit-transition: all .1s ease;
	transition: all .1s ease;
	border-radius: 4px;
	-webkit-box-shadow: 0 17px 34px 0 rgba(0, 0, 0, 0.05), 0 8px 14px 0
		rgba(0, 0, 0, 0.02);
	box-shadow: 0 17px 34px 0 rgba(0, 0, 0, 0.05), 0 8px 14px 0
		rgba(0, 0, 0, 0.02);
	padding-top: 0;
	display: none
}

@media only screen and (max-width: 399px) and (min-width: 300px) {
	.navbar-toggle.open span {
		height: 2px;
		width: 49px
	}
    .fullmenuwrapper .maincontainer {
        width: 100%;
        margin: inherit;
        display: block
    }
    .menuwraper>ul {
		display: inline-block !important;
		width: 100% !important;
		float: left !important
	}
	.menuwraper>ul>li {
		height: 100%;
		align-items: center;
		display: flex;
		box-sizing: border-box;
		position: relative !important;
		padding-top: 0 !important;
		padding-bottom: 0 !important;
		border-bottom: 1px solid #c0801a !important
	}
	.menuwraper>ul>li>a {
		border-right: 0 solid #da921f;
		width: 100% !important;
		justify-content: left;
		padding: 10px !important
	}
	.has-submenu.open {
		display: inline-block;
		width: 100%
	}
	.submenuwrap.open {
		display: block;
		position: relative;
		visibility: visible !important;
		opacity: 1 !important;
		margin-top: 0;
		width: 100%;
		box-sizing: border-box;
		padding-bottom: 0
	}
	.submenuwrap.open li a {
		background-color: #da9321
	}
	.submenuwrap.open li a:hover {
		background-color: #ca861b
	}
	.mainmenubox>li .submenuwrap>li .submenuwrap {
		left: 0%;
		/* top: 35px; */
		top: 0;
		margin-top: 0;
		width: 100%;
		background-color: #ca861b
	}
	.main-dropdown{
		background-image: url('../images/down-chevron.svg');
		background-position: 97% center;
		background-repeat: no-repeat;
	}
	.submenuwrap li a {
		background-color: #da9321 !important;
	}
	.submenuwrap {
		position: relative;
	}
	.menuwraper>ul>li {
		display: block;
	}
}

@media only screen and (max-width: 499px) and (min-width: 400px) {
	
	.navbar-toggle.open span {
		height: 2px;
		width: 49px
	}
	/* .fullmenuwrapper {
		width: 100% !important;
		display: none;
		height: 233px;
		position: relative !important;
		overflow: scroll
	} */
    .fullmenuwrapper .maincontainer {
		width: 100%;
		margin: inherit;
		display: block
	}
    .menuwraper {
		height: inherit !important
	}
	.menuwraper>ul {
		display: inline-block !important;
		width: 100% !important;
		float: left !important
	}
	.menuwraper>ul>li {
		height: 100%;
		align-items: center;
		display: flex;
		box-sizing: border-box;
		position: relative !important;
		padding-top: 0 !important;
		padding-bottom: 0 !important;
		border-bottom: 1px solid #c0801a !important
	}
	.menuwraper>ul>li>a {
		border-right: 0 solid #da921f;
		width: 100% !important;
		justify-content: left;
		padding: 10px !important
	}
	ul.submenu {
		top: 37px !important
	}
	.menuwraper {
		display: inline-block !important;
		width: 100% !important
	}
	.has-submenu.open {
		display: inline-block;
		width: 100%
	}
	.submenuwrap.open {
		display: block;
		position: relative;
		visibility: visible !important;
		opacity: 1 !important;
		margin-top: 0;
		width: 100%;
		box-sizing: border-box;
		padding-bottom: 0
	}
	.submenuwrap.open li a {
		background-color: #da9321
	}
	.submenuwrap.open li a:hover {
		background-color: #ca861b
	}
	.mainmenubox>li .submenuwrap>li .submenuwrap {
		left: 0%;
		/* top: 0; */
		margin-top: 0;
		width: 100%;
		background-color: #ca861b
	}
	.main-dropdown{
		background-image: url('../images/down-chevron.svg');
		background-position: 97% center;
		background-repeat: no-repeat;
	}
	.submenuwrap li a {
		background-color: #da9321 !important;
	}
	.submenuwrap {
		position: relative;
	}
	.menuwraper>ul>li {
		display: block;
	}
}

@media only screen and (max-width:599px) and (min-width: 500px) {
	.navbar-toggle.open span {
		height: 2px;
		width: 49px
	}
    /* .fullmenuwrapper {
		width: 100% !important;
		display: none;
		height: calc(100vh - 140px);
		position: relative !important;
		overflow: scroll
	} */
	.fullmenuwrapper .maincontainer {
		width: 100%;
		margin: inherit;
		display: block
	}
    .menuwraper ul {
		display: none
	}
    .menuwraper {
		height: inherit !important
	}
	.menuwraper>ul {
		display: inline-block !important;
		width: 100% !important;
		float: left !important
	}
	.menuwraper>ul>li {
		height: 100%;
		align-items: center;
		display: flex;
		box-sizing: border-box;
		position: relative !important;
		padding-top: 0 !important;
		padding-bottom: 0 !important;
		border-bottom: 1px solid #c0801a !important
	}
	.menuwraper>ul>li>a {
		padding-left: 10px;
		padding-right: 10px;
		border-right: 0 solid #da921f;
		width: 100% !important;
		justify-content: left
	}
	ul.submenu {
		top: 37px !important
	}
	.menuwraper {
		display: inline-block !important;
		width: 100% !important
	}
	.has-submenu.open {
		display: inline-block;
		width: 100%
	}
	.submenuwrap.open {
		display: block;
		position: relative;
		visibility: visible !important;
		opacity: 1 !important;
		margin-top: 0;
		width: 100%;
		box-sizing: border-box;
		padding-bottom: 0
	}
	.submenuwrap.open li a {
		background-color: #da9321
	}
	.submenuwrap.open li a:hover {
		background-color: #ca861b
	}
	.mainmenubox>li .submenuwrap>li .submenuwrap {
		position: relative;
		left: 0%;
		/* top: 0; */
		margin-top: 0;
		width: 100%;
		background-color: #ca861b
	}
	.main-dropdown{
		background-image: url('../images/down-chevron.svg');
		background-position: 97% center;
		background-repeat: no-repeat;
	}
	.submenuwrap li a {
		background-color: #da9321 !important;
	}
	.submenuwrap {
		position: relative;
	}
	.menuwraper>ul>li {
		display: block;
	}
}

@media only screen and (max-width:699px) and (min-width: 600px) {
	.navbar-toggle.open span {
		height: 2px;
		width: 49px
	}
    /* .fullmenuwrapper {
		width: 100% !important;
		display: none;
		height: 233px;
		position: relative !important;
		overflow: scroll
	} */
	.fullmenuwrapper .maincontainer {
		width: 100%;
		margin: inherit;
		display: block
	}
    .menuwraper ul {
		display: none
	}
    .menuwraper {
		height: inherit !important
	}
	.menuwraper>ul {
		display: inline-block !important;
		width: 100% !important;
		float: left !important
	}
	.menuwraper>ul>li {
		height: 100%;
		align-items: center;
		display: flex;
		box-sizing: border-box;
		position: relative !important;
		padding-top: 0 !important;
		padding-bottom: 0 !important;
		border-bottom: 1px solid #c0801a !important
	}
	.menuwraper>ul>li>a {
		border-right: 0 solid #da921f;
		width: 100% !important;
		justify-content: left;
		padding: 10px !important
	}
	.menuwraper {
		display: inline-block !important;
		width: 100% !important
	}
	.has-submenu.open {
		display: inline-block;
		width: 100%
	}
	.submenuwrap.open {
		display: block;
		position: relative;
		visibility: visible !important;
		opacity: 1 !important;
		margin-top: 0;
		width: 100%;
		box-sizing: border-box;
		padding-bottom: 0
	}
	.submenuwrap.open li a {
		background-color: #da9321
	}
	.submenuwrap.open li a:hover {
		background-color: #ca861b
	}
	.mainmenubox>li .submenuwrap>li .submenuwrap {
		position: relative;
		left: 0%;
		/* top: 0; */
		margin-top: 0;
		width: 100%;
		background-color: #ca861b
	}
	/* .main-dropdown{
		background-image: url('../images/down-chevron.svg');
		background-position: 97% center;
		background-repeat: no-repeat;
	} */
	
}
@media only screen and (max-width: 699px) and (min-width: 600px){
	.submenuwrap li a {
		background-color: #da9321 !important;
	}
	.submenuwrap {
		position: relative;
	}
	.menuwraper>ul>li {
		display: block;
	}
}
@media screen and (max-width: 767px){
	.fullmenuwrapper {
		max-height: calc(100vh - 140px);
		height: 100%;
		overflow: scroll;
	}
}

@media only screen and (max-width: 767px) and (min-width: 700px) {
	.navbar-toggle.open span {
		height: 2px;
		width: 49px
	}
	.fullmenuwrapper .maincontainer {
		width: 100%;
		margin: inherit;
		display: block
	}
    .menuwraper ul {
		display: none
	}
    .menuwraper {
		height: inherit !important
	}
	.menuwraper>ul {
		display: inline-block !important;
		width: 100% !important;
		float: left !important
	}
	.menuwraper>ul>li {
		height: 100%;
		align-items: center;
		display: flex;
		box-sizing: border-box;
		position: relative !important;
		padding-top: 0 !important;
		padding-bottom: 0 !important;
		border-bottom: 1px solid #c0801a !important
	}
	.menuwraper>ul>li>a {
		border-right: 0 solid #da921f;
		width: 100% !important;
		justify-content: left;
		padding: 10px !important
	}
	.menuwraper {
		display: inline-block !important;
		width: 100% !important
	}
	.has-submenu.open {
		display: inline-block;
		width: 100%
	}
	.submenuwrap.open {
		display: block;
		position: relative;
		visibility: visible !important;
		opacity: 1 !important;
		margin-top: 0;
		width: 100%;
		box-sizing: border-box;
		padding-bottom: 0
	}
	.submenuwrap.open li a {
		background-color: #da9321
	}
	.submenuwrap.open li a:hover {
		background-color: #ca861b
	}
	.mainmenubox>li .submenuwrap>li .submenuwrap {
		position: relative;
		left: 0%;
		/* top: 0; */
		margin-top: 0;
		width: 100%;
		background-color: #ca861b
	}
	.main-dropdown{
		background-image: url('../images/down-chevron.svg');
		background-position: 97% center;
		background-repeat: no-repeat;
	}
}

@media only screen and (max-width: 799px) and (min-width: 700px) {
    .fullmenuwrapper .maincontainer {
		width: 100% !important
	}
	/* .container .mw-full {
			max-width: '100%';
	} */
	
}

@media only screen and (max-width: 899px) and (min-width: 800px) {
    .fullmenuwrapper .maincontainer {
		width: 100% !important
	}
}

@media only screen and (max-width: 999px) and (min-width: 900px) {
    .fullmenuwrapper .maincontainer {
		width: 100% !important
	}
}

@media only screen and (max-width: 1099px) and (min-width: 1000px) {

.fullmenuwrapper .maincontainer {
    width: 91% !important
}
}
@media only screen and (max-width: 499px) and (min-width: 300px) {
	.menuwraper ul {
		display: none
	}
}

@media only screen and (max-width: 1199px) and (min-width: 1100px) {
    .fullmenuwrapper .maincontainer {
		width: 91% !important
	}
}
@media only screen and (max-width: 699px) and (min-width: 300px) {
	.submenuwrap.open {
		left: 0 !important;
		margin-top: 0 !important
	}
}
@media screen and (max-width:991px) {
	.menuwraper>ul>li>a {
		font-size: 12px;
		padding-left: 12px;
		padding-right: 12px
	}
}

@media only screen and (max-width: 993px) and (min-width: 300px) {
	.has-submenu.open>a {
		background-color: #bd7a10
	}
	.has-submenu.open>a:hover {
		background-color: #bd7a10
	}
	.has-submenu>a {
		/* background-image: url(../images/down-chevron.svg); */
		background-position: 97% center;
		background-repeat: no-repeat
	}
}

@media screen and (max-width:767px) {
    .menuwraper>ul>li>a, ul.mainmenu li>ul>li>a {
        padding: 9px 16px
    }
    .menuwraper {
        height: auto;
        display: inline-block;
        width: 100%
    }
    .search_wrap {
        padding: 9px 16px;
        width: 100%
    }
    .search_wrap input {
        width: 100%
    }
	.has-submenu>a {
		/* background-image: url(../images/down-chevron.svg); */
		background-position: 97% center;
		background-repeat: no-repeat
	}
	/* .mw-full{
		max-width: '100%';
	} */
}

.submenuwrap::-webkit-scrollbar {
	width: 4px
}

.submenuwrap::-webkit-scrollbar-track {
	background: rbga(255, 255, 255, 0.8);
	border-radius: 4px
}

.submenuwrap::-webkit-scrollbar-thumb {
	background: rgba(0, 0, 0, 0.30);
	border-radius: 4px
}

.submenuwrap::-webkit-scrollbar-thumb:hover {
	background: #555
}

.homeicon{
	padding-top:3px
}


