
/* @import '/fonts/DS-Digital-Bold.woff2'; */

body {
  /* margin: 0; */
  font-family: 'Open Sans', sans-serif;
  /* -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; */
}


@font-face {
	font-family: 'Open Sans';
	src: url(./fonts//OpenSans-Bold.woff2),
		url(./fonts/OpenSans-Bold.woff) format('woff'),
		url(./fonts/OpenSans-Bold.ttf) format('truetype');
	font-weight: bold;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans';
	src: url(./fonts/OpenSans-Regular.woff2) format('woff2'),
		url(./fonts/OpenSans-Regular.woff) format('woff'),
		url(./fonts/OpenSans-Regular.ttf) format('truetype');
	font-weight: normal;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans';
	src: url(./fonts/OpenSans-ExtraBold.woff2) format('woff2'),
		url(./fonts/OpenSans-ExtraBold.woff) format('woff'),
		url(./fonts/OpenSans-ExtraBold.ttf) format('truetype');
	font-weight: 800;
	font-style: normal;
	font-display: swap;
}

@font-face {
	font-family: 'Open Sans';
	src: url(./fonts/OpenSans-SemiBold.woff2) format('woff2'),
		url(./fonts/OpenSans-SemiBold.woff) format('woff'),
		url(./fonts/OpenSans-SemiBold.ttf) format('truetype');
	font-weight: 600;
	font-style: normal;
	font-display: swap;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* .form-control {
  font-size: 13px;
  font-weight: normal;
}

h5{
  font-size: 14px;
  font-weight: 600;
}

.bg-profile-tabs{
  background-color: #F2F2F2;
} */