.user_details {
    padding: 8px 15px 8px 20px;
    display: block;
    font-size: 14px;
    position: relative;
    font-weight: 400;
    letter-spacing:0.5px;
    /* color: #fff; */
    color: #5f5f5f;
    margin-top: 20px;
  }

  
.sidebar {
    /* background: #1A202E; */
    background: #F5F7F9;
    position: fixed;
    /* height: -webkit-fill-available; */
    /* overflow: scroll; */
    height: calc(100vh - 100px);;
    margin-top:100px;
    width: 235px;
    /* position: fixed;
    top: 112px;
    height: calc(100% - 112px);
    transition: 0.5s all;
    overflow-y:auto;
    overflow-x:hidden;
    padding: 0px; */
}

.sidebar ul > li > a {
    padding: 8px 15px 8px 20px;
    display: block;
    font-size: 13px;
    position: relative;
    font-weight: 400;
    letter-spacing:0.5px;
    /* color: #fff; */
    color: #5f5f5f;
    
}

/* .sidebar ul > li > a.active {background-color:#363A45;}
.sidebar ul > li > a:hover {background-color:#363A45;text-decoration:none;} */
.sidebar ul > li > a.active {background-color:#e7e7e7;}
.sidebar ul > li > a:hover {background-color:#e7e7e7;text-decoration:none;}

.fa { padding-right: 5px;}


.sidebar > ul {
    padding: 10px 0;
    margin: 0;
}

.mg_top100{
    margin-top: 100px
}

.ad_login_sidebar {
    background: #1A202E;
    position: fixed;
    height: -webkit-fill-available;
    width: 270px
}
