.App {
  text-align: center;
}
.main_content {
  padding-top: 20px;
  padding-bottom: 20px
}
.App-logo {
  height: 40vmin;
  pointer-events: none;
}
*{
  -ms-overflow-style: none;
}
/* ::-webkit-scrollbar {
  display: none;
} */
.userProfileCss {
  right: -10px;
  top: -10px;
  z-index: 10;
}
.imgLogoCss {
  height: 20px;
  width: 20px;
}
.css-12i7wg6-MuiPaper-root-MuiDrawer-paper{
  top:auto !important;
  background-color: #1A202E !important;
    color: rgb(255 255 255 / 87%) !important;
}
.css-4t3x6l-MuiPaper-root-MuiDrawer-paper {
  top:auto !important;
  background-color: #1A202E !important;
    color: rgb(255 255 255 / 87%) !important;
}

.disabled-div {
  background-color: #f2f2f2; 
  color: #999; 
  cursor: not-allowed; 
  /* opacity: 0.5;  */
  pointer-events: none; 
  /* border: 1px solid #ccc;  */
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}
.d-pointer {
  cursor: pointer;
}
.arrow-disable {
  color: #b5b5b5
}

.b-shadow-none {
  box-shadow: none !important;
}
.fwidth-65p {
  width: 65px;
}
.fwidth-35p {
  width: 35px;
}
.min-hgt-450{
	min-height: 450px
}
.minw-18p {
  min-width: 18px;
}

/* common table */
.error-center-text {
  color: red;
  text-align : center
}
.table-header-effect {
  cursor: pointer;
  min-width:150px
}
.sort-arrow-block {
  display: flex;
  justify-content: flex-end;
  margin: auto 0 0;
}
.m-file {
  margin-right: .5rem
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
/* .footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
} */
@media only screen and (max-width: 1400px) and (min-width: 1024px) {
  .footer {
    /* position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%; */
    /* position: fixed; */
    left: 0;
    bottom: 0px;
    width: 100%;
    
    /* background-color: #0075a2; */
    /* color: white; */
    /* padding: 10px; */
  }
}


.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.disabled-link {
  pointer-events: none;
  color: grey;
}

.post-pref-item .arrange-arrows {
  opacity: 0;
}
.post-pref-item:hover .arrange-arrows {
  opacity: 1;
}
.arrange-arrows .fa {
  cursor: pointer;
}

.appLoader {    
  background-color: rgba(255, 255, 255, 0.7);
  display: block;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  position: fixed;
  text-align: center;
  z-index: 9999;
  padding-top:25%;
  font-size: 15px !important;
  color: #0075a2 !important;
};

.appLoader spinner {    
  width: 50px;
  height: 50px;       
};
.retotalingSection .tab-content>.active {
  display: block;
  overflow: hidden;
  min-height: 300px;
  overflow-y: auto;
}

.startCapitalize {
  text-transform: capitalize;
}

.react-datepicker__input-container .react-datepicker__calendar-icon {
  box-sizing: initial;
  padding: .7rem;
  position: absolute;
}

.react-datepicker__calendar-icon {
  height: 1em;
  vertical-align: -.125em;
  width: 1em;
}
.react-datepicker__view-calendar-icon input {
  padding: 6px 10px 5px 30px;
}