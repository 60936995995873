.select_custom {
    width: 100% !important; 
    height: 38px !important; 
    max-width: 100% !important
}

.margin_t_150px{
    margin-top: 150px;
}

.w_50 {
    width: 50;
}
.w_200 {
    width: 200;
}

.css-og52vs-MuiTableCell-root.MuiTableCell-head {
    background-color: #a1a1a1 !important;
    /* color: #fff; */
}
.css-1wvnaxz-MuiTableCell-root.MuiTableCell-head {
    background-color: #a1a1a1  !important;
    /* color: #fff; */
}