header {background:url(../../images/bg.png) center;background-size:cover;height: 112px; overflow:hidden; position: fixed; z-index: 999; width: 100%;}
.header_wrap{display:flex;align-items:center;justify-content:center;padding: 18px 0;}

.header_wrap > figure{margin:0;display:block}
.header_wrap > figure > img{height:100%;display:block;width:auto;max-height:76px}
.header_wrap > .mpsc_txt{padding:0 16px}
.header_wrap > .mpsc_txt > img{max-height:66px;margin-top:-7px}
.z-index-set-10{
    z-index: 10;
}
.main_header_wf{
    width: 100%;
     position:fixed;
     z-index: 10
}