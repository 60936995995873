.middlemnuerap {
	background-color: #7e0001;
	height: 44px;
	display: flex;
	justify-content: center;
	align-items: center;
	width: 100%
}
.middlemnuerap ul {
	display: flex;
	width: 100%;
	justify-content: center;
	align-items: center;
	height: 45px;
/*	line-height: 40px; */
	margin: 0;
	text-align:center;
	
}
.middlemnuerap ul li:last-child a {
	border-right: 0
}

.maincontainer.innercontainer .middlemnuerap ul li a {
	font-size: 11px
}

.middlemnuerap ul li a {
	color: #ebebeb;
	font-size: 12px;
	padding-left: 8px;
	padding-right: 8px;
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center
}

.middlemnuerap ul li a:hover {
	color: #fff;
	background-color: #5b0303;
	text-decoration: none;
}
.middlemnuerap ul li {
	height: 100%;
	display: flex;
	justify-content: center;
	align-items: center;
	border-right: 1px solid #fff
}
.upwrapperfull .maincontainer {
	width: 1139px
}
.upwrapperfull {
	background-color: #f5aa33;
	/* padding: 10px 0 */
	padding: 5px 0
}
.updatewrap {
	display: flex;
	justify-content: space-between
}
.updatebox p {
	font-size: 12px;
	font-weight: 700;
	margin: 0
}
.footer_bottom {
	background-color: #354a89;
	/* padding-top: 10px;
	padding-bottom: 10px */
	padding-top: 5px;
	padding-bottom: 5px
}

.bottom_wrap {
	justify-content: space-between;
	display: flex;
	align-items: center
}

.bottom_wrap .bmiddlebox {
	margin: 0;
	color: #fff;
	font-size: 13px
}

.bottom_wrap .bleftbox {
	margin: 0
}

.bottom_wrap .bleftbox a {
	color: #fff;
	font-size: 13px
}

.bottom_wrap .brightbox {
	margin: 0;
}
.bottom_wrap .brightbox img {
	width: auto;
}
.bottommenu ul {
	width: 90%
}
.bottommenu ul li a {
	color: #fff;
	font-size: 14px
}
.bottommenu.bleftbox {
	line-height: 13px;
	display: flex;
	justify-content: left;
	align-items: center;
	border-bottom: 0 solid #fff
}
@media screen and (max-width:991px) {
    .bottom_wrap .bmiddlebox {
		font-size: 12px;
		max-width: 400px
	}
}

@media screen and (max-width:767px) {
    .bottom_wrap {
        flex-direction: column
    }
    .bottom_wrap .bleftbox, .bottom_wrap .bmiddlebox {
        margin-bottom: 8px;
        max-width: auto;
        text-align: center
    }
}

@media only screen and (max-width: 1399px) and (min-width: 1300px) {
	.footer_bottom {
		width: 100% !important;
		display: block !important
	}
	.headtopwrap {
		width: 100%;
		height: 124px
	}
}
@media only screen and (max-width: 1999px) and (min-width: 1900px) {
	.middlemnuerap ul li a {
		padding-left: 15px;
		padding-right: 15px
	}
}

@media only screen and (max-width: 399px) and (min-width: 300px) {
    .middlemnuerap {
        height: auto !important;
        padding-top: 16px
    }
    .middlemnuerap ul {
        display: inline-block !important;
        width: 100% !important;
        justify-content: center;
        align-items: center;
        height: 100% !important
    }
    
}

@media only screen and (max-width: 399px) and (min-width: 300px) {
    .middlemnuerap {
		height: auto !important;
		padding-top: 16px
	}
	.middlemnuerap ul {
		display: inline-block !important;
		width: 100% !important;
		justify-content: center;
		align-items: center;
		height: 100% !important
	}
    .middlemnuerap ul li {
		height: 100% !important;
		margin-bottom: 16px !important
	}
    .upwrapperfull .maincontainer {
		width: 97% !important;
		margin: auto !important
	}
    .updatebox p {
		font-size: 10px
	}
}

@media only screen and (max-width: 499px) and (min-width: 400px) {
    .middlemnuerap {
		height: auto !important;
		display: inline-block !important;
		justify-content: center;
		align-items: center;
		width: 100% !important;
		padding-top: 16px
	}
	.middlemnuerap ul {
		display: inline-block !important;
		width: 100% !important;
		justify-content: center;
		align-items: center;
		height: 100% !important
	}
    .middlemnuerap ul li {
		height: 100% !important;
		margin-bottom: 16px !important
	}
    
	.upwrapperfull {
		width: 100% !important;
		box-sizing: border-box
	}
	.upwrapperfull .maincontainer {
		width: 97% !important;
		margin: auto !important
	}
    .updatewrap {
		display: flex !important;
		justify-content: center;
	}
    .updatebox p {
		font-size: 10px
	}
}
@media only screen and (max-width:599px) and (min-width: 500px) {
    .middlemnuerap {
		height: auto !important;
		display: inline-block !important;
		justify-content: center;
		align-items: center;
		width: 100% !important;
		padding-top: 16px
	}
    .upwrapperfull {
		width: 100% !important;
		box-sizing: border-box
	}
	.upwrapperfull .maincontainer {
		width: 97% !important;
		margin: auto !important
	}
    .updatewrap {
		display: flex !important;
		justify-content: center;
	}
    .updatebox p {
		font-size: 10px
	}
	.middlemnuerap ul {
		display: inline-block !important;
		width: 100% !important;
		justify-content: center;
		align-items: center;
		height: 100% !important
	}
    .middlemnuerap ul li {
		height: 100% !important;
		margin-bottom: 16px !important
	}
}

@media only screen and (max-width:699px) and (min-width: 600px) {
    .middlemnuerap {
		height: auto !important;
		display: inline-block !important;
		justify-content: center;
		align-items: center;
		width: 100% !important;
		padding-top: 16px
	}
	.middlemnuerap ul {
		display: inline-block !important;
		width: 100% !important;
		justify-content: center;
		align-items: center;
		height: 100% !important
	}
    .middlemnuerap ul li {
		height: 100% !important;
		margin-bottom: 16px !important
	}
    .upwrapperfull {
		width: 100% !important;
		box-sizing: border-box
	}
	.upwrapperfull .maincontainer {
		width: 97% !important;
		margin: auto !important
	}
    .updatewrap {
		display: flex !important;
		justify-content: center;
	}
    .updatebox p {
		font-size: 10px
	}
 }

 @media only screen and (max-width: 767px) and (min-width: 700px) {
	.middlemnuerap {
		height: auto !important;
		display: inline-block !important;
		justify-content: center;
		align-items: center;
		width: 100% !important;
		padding-top: 16px
	}
	.middlemnuerap ul {
		display: inline-block !important;
		width: 100% !important;
		justify-content: center;
		align-items: center;
		height: 100% !important
	}
    .middlemnuerap ul li {
		height: 100% !important;
		margin-bottom: 16px !important;
		border-right: none
	}
    .upwrapperfull {
		width: 100% !important;
		box-sizing: border-box
	}
	.upwrapperfull .maincontainer {
		width: 97% !important;
		margin: auto !important
	}
    .updatewrap {
		display: flex !important;
		justify-content: center;
	}
 }

 @media only screen and (max-width: 799px) and (min-width: 700px) {
    .middlemnuerap ul li a {
		color: #ebebeb
	}
    /* .upwrapperfull .maincontainer {
		width: 96% !important
	} */
	/* .middlemnuerap {
		height: auto !important;
		display: inline-block !important;
		justify-content: center;
		align-items: center;
		width: 100% !important;
		padding-top: 16px
	}
	.middlemnuerap ul {
		display: inline-block !important;
		width: 100% !important;
		justify-content: center;
		align-items: center;
		height: 100% !important
	}
    .middlemnuerap ul li {
		height: 100% !important;
		margin-bottom: 16px !important;
		border-right: none
	} */
    /* .upwrapperfull {
		width: 100% !important;
		box-sizing: border-box
	} */
	/* .upwrapperfull .maincontainer {
		width: 97% !important;
		margin: auto !important
	} */
    /* .updatewrap {
		display: flex !important;
		justify-content: center;
	} */
    .updatebox p {
		font-size: 10px
	}
	
	
 }

 @media only screen and (max-width: 899px) and (min-width: 800px) {
    .middlemnuerap ul li a {
		color: #ebebeb;
		font-size: 10px !important
	}
    .upwrapperfull .maincontainer {
		width: 96% !important
	}
 }
 @media only screen and (max-width: 999px) and (min-width: 900px) {
    .middlemnuerap ul li a {
		color: #ebebeb;
		font-size: 10px !important
	}
    .upwrapperfull .maincontainer {
		width: 96% !important
	}
 }
 @media only screen and (max-width: 1099px) and (min-width: 1000px) {
    .middlemnuerap ul li a {
		color: #ebebeb;
		font-size: 10px !important
	}
 }
 @media only screen and (max-width: 1199px) and (min-width: 1100px) {
    .middlemnuerap ul li a {
		color: #ebebeb
	}
    .upwrapperfull .maincontainer {
		width: 1030px
	}
	.upwrapperfull {
		background-color: #f5aa33;
		width: 100%;
		display: inline-block
	}
}

@media only screen and (max-width: 1299px) and (min-width: 1200px) {
    .middlemnuerap ul li a {
		color: #ebebeb
	}
    .upwrapperfull .maincontainer {
		width: 1030px
	}
	.upwrapperfull {
		background-color: #f5aa33;
		width: 100%;
		display: inline-block
	}
}

@media screen and (max-width:991px) {
	.menuwraper>ul>li>a {
		font-size: 12px;
		padding-left: 12px;
		padding-right: 12px
	}
	.middlemnuerap ul li {
		min-height: 44px
	}
	.middlemnuerap ul {
		line-height: normal
	}
	.middlemnuerap ul li a {
		padding: 8px;
		font-size: 11px
	}
	.bottom_wrap .bmiddlebox {
		font-size: 12px;
		max-width: 400px
	}
}

@media screen and (max-width:767px) {
    .middlemnuerap ul {
		display: flex;
		flex-direction: column
	}
	.middlemnuerap ul li {
		width: 100%;
		border: 0;
		margin-bottom: 8px;
		min-height: auto
	}
	.middlemnuerap ul li a {
		width: 100%;
		padding: 4px 0
	}
}
@media screen and (max-width:479px) {
	.updatewrap {
		display: flex;
		flex-direction: column;
		align-items: center
	}
	.updatewrap .uprightbox {
		margin-top: 15px;
		text-align: center
	}
    
}
.updatebox {
	display: flex;
	align-items: center
}

.well-sm {
    /* padding: 9px; */
    padding: 5px;
}

.well {
    background-color: #f5f5f5;
}

.img-rounded {
    border-radius: 6px;
}

.custom-img {
	width: 110px;
	height: 45px;
	margin-left: 10px;
}